import { captureRemixErrorBoundaryError } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError
} from '@remix-run/react';
import resetStyles from '~/styles/settings/normalize.css?url';
import variableStyles from '~/styles/settings/variables.css?url';
import colorStyles from '~/styles/settings/colors.css?url';
import typographySettingsStyles from '~/styles/settings/typography.css?url';
import typographyStyles from '~/styles/atoms/typography.css?url';
import layoutStyles from '~/styles/atoms/layout.css?url';
import buttonStyles from '~/styles/atoms/button.css?url';
import logoStyles from '~/styles/atoms/logo.css?url';
import formStyles from '~/styles/atoms/form.css?url';
import animationStyles from '~/styles/settings/animation.css?url';
import globalStyles from '~/styles/global.css?url';
import { LinksFunction, MetaFunction } from '@remix-run/node';

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
        <script
          src="//code.tidio.co/hke398sutjnn2kfhxopwjqyuq3h0irjp.js"
          async
        ></script>
      </body>
    </html>
  );
}

export const meta: MetaFunction = () => [
  {
    charset: 'utf-8',
    title: 'UnlockAI',
    viewport: 'width=device-width,initial-scale=1'
  }
];

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: resetStyles },
  { rel: 'stylesheet', href: variableStyles },
  { rel: 'stylesheet', href: colorStyles },
  { rel: 'stylesheet', href: typographySettingsStyles },
  { rel: 'stylesheet', href: typographyStyles },
  { rel: 'stylesheet', href: animationStyles },
  { rel: 'stylesheet', href: layoutStyles },
  { rel: 'stylesheet', href: buttonStyles },
  { rel: 'stylesheet', href: logoStyles },
  { rel: 'stylesheet', href: formStyles },
  { rel: 'stylesheet', href: globalStyles }
];

export function ErrorBoundary() {
  let error = useRouteError();

  console.log(error);

  captureRemixErrorBoundaryError(error);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16
      }}
    >
      <img
        src="https://brave-admin-assets.s3.amazonaws.com/images/gokhos-logo.png"
        alt="Logo"
        style={{ margin: 0, width: 100, height: 100 }}
      />
      <h1 style={{ fontSize: 24, fontWeight: 600 }}>
        Oops! Looks like something unexpected happened.
      </h1>
      <p>Our team has been notified and are investigating the issue.</p>
      <a href="/" style={{ textDecoration: 'underline' }}>
        Go back home
      </a>
    </div>
  );
}

export default function App() {
  return <Outlet />;
}
